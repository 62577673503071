import React, { useEffect, useRef } from 'react';
import Rellax from 'rellax';

import image1 from 'constants/images/img-one.jpg';
import image2 from 'constants/images/img-two.jpg';
import image3 from 'constants/images/img-free.jpg';

const BackgroundImage = React.memo(({ image }) => {
  return (
    <div
      className="img"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
      }}
    ></div>
  );
});

export default React.memo(props => {
  const parallaxImage1Ref = useRef(null);
  const parallaxImage2Ref = useRef(null);
  const parallaxImage3Ref = useRef(null);

  useEffect(() => {
    const el = parallaxImage1Ref.current;
    let parallax;
    if (image1 && el) {
      parallax = new Rellax(el, { center: true, speed: -1 });
      parallax.refresh();
    }

    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  }, [parallaxImage1Ref]);

  useEffect(() => {
    const el = parallaxImage2Ref.current;
    let parallax;
    if (el) parallax = new Rellax(el, { center: true, speed: 0 });

    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  }, [parallaxImage2Ref]);

  useEffect(() => {
    const el = parallaxImage3Ref.current;
    let parallax;
    if (el) parallax = new Rellax(el, { center: true, speed: 1 });

    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  }, [parallaxImage3Ref]);

  return (
    <div
      className="section section-cover-image fp-auto-height no-footer anim"
      data-section=""
    >
      <div className="section-cover-column parallax-anim cover-left">
        <div
          className="flex-even img1 parallax-speed-1"
          ref={parallaxImage1Ref}
        >
          <BackgroundImage image={image1} />
        </div>
        <div
          className="flex-even img2 parallax-speed-0"
          ref={parallaxImage2Ref}
        >
          <BackgroundImage image={image2} />
        </div>
        <div
          className="flex-even img3 parallax-speed-2"
          ref={parallaxImage3Ref}
        >
          <BackgroundImage image={image3} />
        </div>
      </div>
    </div>
  );
});
