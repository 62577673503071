import {
  faFacebookF, faInstagram
} from '@fortawesome/free-brands-svg-icons';

import {
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons';

const Icons = [
  faFacebookF, faInstagram, faCheckSquare
];

export default Icons;
