import React from 'react';
import kirkova from 'constants/images/kirkova.com.jpg';
import solis from 'constants/images/solis.com.jpg';
import propertynexus from 'constants/images/propertynexus.co.jpg';
import moodTravel from 'constants/images/mood.travel.jpg';

const portfolio = [
  {
    img: kirkova,
    text:
      'Platform for online courses. The platform has a smart configuration for different courses, also has a built-in messenger.',
    site: 'https://kirkova.com'
  },
  {
    img: moodTravel,
    text:
      'Booking platform for B2C market. Innovative platform including CRM with API, search and booking engines, online payments.',
    site: 'https://mood.travel'
  },
  {
    img: solis,
    text:
      'CRM, Booking and Search engines for leader of the travel DMC in Mauritius. The platform was developed from scratch. Multicurrency, individual rates and conditions for any of the clients of Solis 360.',
    site: 'https://solisconnect.com'
  },
  {
    img: propertynexus,
    text:
      "Country specific CRM software catering for Africa's growing Real Estate Market. This CRM provides all the features necessary to increase sales revenue while reducing the administration work involved.",
    site: 'https://propertynexus.co'
  }
];

export default React.memo(() => {
  return (
    <div className="section fp-auto-height-responsive">
      <div className="section-wrapper with-margin py-0">
        <div className="section-content anim">
          {portfolio.map((item, index) => (
            <div
              className="row"
              key={index}
              style={{
                marginBottom: '5rem',
                marginTop: '5rem'
              }}
            >
              <div className="col-sm-12 col-md-6 d-flex justify-content-center p-4 align-items-center bg-white text-dark">
                <a href={item.site} target="_blank" rel="noopener noreferrer">
                  <img src={item.img} alt="site" />
                </a>
              </div>
              <div className="col-sm-12 col-md-6 d-flex justify-content-center p-4 align-items-center">
                <div className="portfolio-block">
                  <div className="portfolio-desc">{item.text}</div>
                  <div className="portfolio-site">
                    {'site: '}
                    <a
                      href={item.site}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {new URL(item.site).hostname}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
