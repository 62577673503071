import React from 'react';

export default React.memo(() => {
  return (
    <div
      className="section section-list-feature fp-auto-height-responsive"
      data-section="service"
    >
      <div className="section-title title-top" data-rellax-speed="1">
        <h2 className="text-stroke text-primary parallax-anim">services</h2>
      </div>
      <div className="section-wrapper with-margin">
        <div className="section-content anim list-line-margin">
          <div className="item row">
            <div className="col-12 text-left">
              <div className="title-desc pr-4">
                <h2 className="display-4 anim-2 text-primary">
                  business process automation.
                </h2>
              </div>
            </div>
            <div className="col-12 col-lg-8 text-left">
              <div className="title-desc display-text-big anim-4">
                <p>
                  We develop products to automate everyday routine for business.
                </p>
              </div>
            </div>
          </div>
          <div className="item row">
            <div className="col-12 text-left">
              <div className="title-desc pr-4">
                <h2 className="display-4 anim-2 text-primary">
                  minimum viable product.
                </h2>
              </div>
            </div>
            <div className="col-12 col-lg-8 text-left">
              <div className="title-desc display-text-big anim-4">
                <p>
                  We develop MVP to collect the maximum amount of validated
                  learning about customers with the least effort.
                </p>
              </div>
            </div>
          </div>
          <div className="item row">
            <div className="col-12 text-left">
              <div className="title-desc pr-4">
                <h2 className="display-4 anim-2 text-primary">big data.</h2>
              </div>
            </div>
            <div className="col-12 col-lg-8 text-left">
              <div className="title-desc display-text-big anim-4">
                <p>
                  We help clients get the most out of their data and build
                  scalable analytics-driven solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
