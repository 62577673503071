import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const FormHooks = (initialValues, callback) => {
  const [inputs, setInputs] = useState(initialValues);

  const handleSubmit = useCallback(
    event => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      callback();
    },
    [callback]
  );

  const handleInputChange = useCallback(event => {
    event.persist();
    let { value } = event.target;
    if (event.target.type && event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    setInputs(inputs => ({ ...inputs, [event.target.name]: value }));
  }, []);

  return {
    handleSubmit,
    handleInputChange,
    inputs
  };
};

FormHooks.propTypes = {
  initialValues: PropTypes.object,
  callback: PropTypes.object
};

export default FormHooks;
