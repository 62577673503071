import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from '../locale/ru';

const resources = {
  ru: {
    translation: ru
  }
};

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  lng: 'ru',
  fallbackLng: 'ru',
  react: {
    wait: false,
    nsMode: 'default'
  },
  selectedLang: 'ru'
});

export default i18n;
