import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap-grid.css';
import 'style/index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import Icons from 'helpers/fontAwesomeIcons';

import * as Sentry from '@sentry/browser';
import { SENTRY_KEY, SENTRY_ORG, SENTRY_PROJECT } from 'constants/sentry';

library.add(Icons);

Sentry.init({
  dsn: `https://${SENTRY_KEY}@${SENTRY_ORG}.ingest.sentry.io/${SENTRY_PROJECT}`,
  release: 'dsa-app@0.1.0'
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
