import React, { useEffect, useRef } from 'react';
import Parallax from 'parallax-js/src/parallax';

export default React.memo(({ handleOnClick }) => {
  const parallaxTextRef = useRef(null);
  const parallaxImgRef = useRef(null);

  useEffect(() => {
    const el = parallaxTextRef.current;
    let parallax;
    if (el) parallax = new Parallax(el);

    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  });

  useEffect(() => {
    const el = parallaxImgRef.current;
    let parallax;
    if (el) parallax = new Parallax(el);

    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  });

  return (
    <div className="section section-home fullscreen-md main-home section-center anim active">
      <div
        id="home-parralax-img"
        ref={parallaxImgRef}
        className="section-title title-centered"
      >
        <h2 className="text-stroke text-primary" data-depth="0.2">
          DS AGENCY
        </h2>
      </div>
      <div className="section-wrapper with-margin">
        <div
          className="section-content anim"
          id="home-parralax-text"
          ref={parallaxTextRef}
        >
          <div
            className="row justify-content-center c-wrapper d-flex"
            data-depth="-0.4"
          >
            <div className="col-12 col-md-8 text-center">
              <div className="title-desc mb-0">
                <h1 className="text-primary display-4 display-title home-title text-no-shadow anim-blur anim-2 mb-4">
                  <span className="">
                    {' '}
                    a digital
                    <br /> solutions agency.
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <footer className="section-footer scrolldown">
          <a className="down" href="/" onClick={handleOnClick}>
            <span className="icon"></span>
            <span className="txt">About us</span>
          </a>
        </footer>
      </div>
    </div>
  );
});
