import React, { lazy, Suspense, useRef } from 'react';
import Footer from 'components/elements/footer';
import MainPage from 'components/elements/mainPage';
import AboutUs from 'components/elements/aboutUs';
import Portfolio from 'components/elements/portfolio';
import ServiceSection from 'components/elements/serviceSection';
import ImageSection from 'components/elements/imageSection';
import ContactSection from 'components/elements/contact';
import ErrorBoundary from 'components/catcher';
import ReactGA from 'react-ga';
import logo from 'constants/images/DSA-logo.png';
import '../config/i18n';

const Video = lazy(() => import('components/elements/bgVideo'));

const trackingId = 'UA-60922323-4';
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(trackingId);
  ReactGA.pageview(window.location.pathname + window.location.search);
  // history.listen(location => {
  //   ReactGA.set({ page: location.pathname });
  //   ReactGA.pageview(location.pathname);
  // });
}

const Main = React.memo(() => {
  const aboutUs = useRef(null);

  const handleOnClick = e => {
    e.preventDefault();
    if (aboutUs.current) {
      aboutUs.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        alignToTop: true
      });
    }
  };

  return (
    <>
      <div className="page-header navbar page-header-drawer menu-right logo-left topmenu-right">
        <a className="navbar-brand" href="./#">
          <span className="logo">
            <img className="light-logo" src={logo} alt="Logo" />
          </span>
        </a>
      </div>
      <div className="page-cover">
        <Suspense fallback={<div>...</div>}>
          <Video />
        </Suspense>
      </div>
      <main className="page-main main-anim scrollpage-container">
        <MainPage handleOnClick={handleOnClick} />
        <AboutUs ref={aboutUs} />
        <ImageSection />
        <ServiceSection />
        <Portfolio />
        <ContactSection />
      </main>
      <Footer />
    </>
  );
});

export default React.memo(() => {
  return (
    <ErrorBoundary>
      <Main />
    </ErrorBoundary>
  );
});
