import React, { useEffect, useRef } from 'react';
import Rellax from 'rellax';

export default React.forwardRef((props, ref) => {
  const parallaxAnimRef = useRef(null);

  useEffect(() => {
    const el = parallaxAnimRef.current;
    let parallax;
    if (el) {
      parallax = new Rellax(el, { center: true, speed: -2 });
      parallax.refresh();
    }
    return function cleanup() {
      if (parallax) parallax.disabled();
    };
  }, [parallaxAnimRef]);

  return (
    <div
      className="section fullscreen-md-off"
      data-section="about-us"
      ref={ref}
    >
      <div className="section-title title-top rellax">
        <h2
          className="text-stroke text-primary parallax-anim"
          ref={parallaxAnimRef}
        >
          about
        </h2>
      </div>
      <div className="section-wrapper with-margin">
        <div className="section-content anim">
          <div className="row">
            <div className="col-12 text-left">
              <div className="title-desc pr-4">
                <h2 className="display-4 anim-2 text-primary">
                  a digital agency.
                </h2>
              </div>
            </div>
            <div className="col-12  text-left">
              <div className="title-desc display-text-big anim-4">
                <p>
                  DSA - is a product development agency that works with startups
                  and businesses, and creates own services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
