import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormHooks from 'helpers/formHooks';
import { send } from 'emailjs-com';
import Message from './message';

import 'swiper/dist/css/swiper.css';

const options = {
  grabCursor: true,
  centeredSlides: false,
  autoplay: 0,
  autoplayDisableOnInteraction: false,
  slidesPerView: 1,
  spaceBetween: 16,
  autoHeight: true,
  fadeEffect: {
    crossFade: true
  },
  allowTouchMove: false
};

export default React.memo(({ dkejkedk }) => {
  const infoRef = useRef(null);
  const messageRef = useRef(null);
  const swiperRef = useRef(null);
  const [contactSwiper, setContactSwiper] = useState();

  const swiperFunc = () => {
    return new Swiper(swiperRef.current, options);
  };

  useEffect(() => {
    if (swiperRef.current) setContactSwiper(swiperFunc());
  }, [swiperRef]);

  const handlerShowInformation = event => {
    event.preventDefault();
    const el = infoRef.current;
    if (el) contactSwiper.slideTo(0, 300, false);
  };

  const handlerShowMessage = event => {
    event.preventDefault();
    const el = messageRef.current;
    if (el) contactSwiper.slideTo(1, 300, false);
  };

  return (
    <div
      className="section section-contact bg-level-2"
      data-section="contact-info"
    >
      <div className="section-title title-top">
        <h2 className="text-stroke text-primary parallax-anim">contact</h2>
      </div>
      <div className="section-wrapper  with-margin">
        <div className="section-content">
          <div className="row">
            <div className="col-12 anim">
              <div className="title-desc pr-4">
                <h2 className="display-4 text-primary anim-2">let's talk.</h2>
              </div>
              <div className="btns-action anim-3 contact-btns-action mb-4">
                <a
                  className="btn btn-line show-contact-information"
                  onClick={handlerShowInformation}
                  href="/"
                >
                  Information
                </a>
                <a
                  className="btn btn-line show-contact-message"
                  onClick={handlerShowMessage}
                  href="/"
                >
                  Message
                </a>
              </div>
            </div>
            <div className="col-12 ">
              <div className="section-content anim text-left">
                <div className="slider-swiper-contact">
                  <div
                    ref={swiperRef}
                    className="swiper-container slider-container"
                  >
                    <div className="item-list swiper-wrapper">
                      <div
                        className="swiper-slide contact-information-slide"
                        ref={infoRef}
                      >
                        <div
                          className="contact-information"
                          id="contact-information"
                        >
                          <div className="title-desc mb-0">
                            <div className="anim-4">
                              <p>
                                Want to start an awesome projects or have
                                questions about our company and products found
                                on our stores? Feel free to talk to us, we will
                                be happy to answser you. Here is how to reach
                                us:
                              </p>
                            </div>
                            <div className="address-container anim-5">
                              <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <h4 className="display-6 display-decor text-primary">
                                    Mauritius
                                  </h4>
                                  <p>
                                    +230 5742 8299
                                    <br />
                                    Notre Dame de la Salette Avenue
                                    <br />
                                    30551 Grand Bay - Mauritius
                                  </p>
                                  <p>info@ds.agency</p>

                                  <div className="icons icons-margin">
                                    <a
                                      href="//fb.me/dsa.dev"
                                      className="btn-icon btn-icon-no-text"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      alt="fb.me/dsa.dev"
                                    >
                                      <FontAwesomeIcon
                                        icon={{
                                          prefix: 'fab',
                                          iconName: 'facebook-f'
                                        }}
                                        className="icon"
                                      />
                                      <span className="no-text">
                                        fb.me/dsa.dev
                                      </span>
                                    </a>
                                    <a
                                      href="//instagram.com/memumen"
                                      className="btn-icon"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FontAwesomeIcon
                                        icon={{
                                          prefix: 'fab',
                                          iconName: 'instagram'
                                        }}
                                        className="icon"
                                      />
                                      <span className="no-text">
                                        instagram.com/memumen
                                      </span>
                                    </a>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                  <h4 className="display-6 display-decor text-primary">
                                    Russia
                                  </h4>
                                  <p>
                                    +7 495 741 0695
                                    <br />
                                    Volgogradskiy Prospekt, 43, к. 3,
                                    <br />
                                    Avilon Plaza, Moscow, Russia, 109316
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="swiper-slide contact-message-slide"
                        ref={messageRef}
                      >
                        <Message />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
