import React, { useState } from 'react';
import FormHooks from 'helpers/formHooks';
import { send } from 'emailjs-com';

const defaultValues = { newName: '', message: '', newEmail: '' };
const userId = process.env.REACT_APP_EMAILJS_USERID;

export default React.memo(() => {
  // const recaptchaRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const callBack = () => {
    const templateParams = { ...inputs };
    setDisabled(true);
    send('smtp_server', 'template_5ojqTV5u', templateParams, userId).then(
      result => setFormSubmitted(true),
      error => {}
    );
  };

  const { inputs, handleInputChange, handleSubmit } = FormHooks(
    defaultValues,
    callBack
  );

  return (
    <div className="contact-message">
      <div className="title-desc">
        <p className="">
          For questions about our company and products found on our stores.
        </p>
      </div>
      <div className="message-form">
        <div className="form-container form-container-card">
          {formSubmitted ? (
            <div>
              <p className="message-ok invisible form-text-feedback form-success-visible">
                Your message has been sent, thank you.
              </p>
            </div>
          ) : (
            <form
              className="send_message_form message form"
              onSubmit={handleSubmit}
              id="message_form"
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group name">
                    <label className="no-text" htmlFor="name">
                      Name
                    </label>
                    <input
                      autoComplete="new-password"
                      name="newName"
                      id="name"
                      type="text"
                      placeholder="Name"
                      value={inputs.newName}
                      className="form-control form-control-outline input-full thick form-success-clean"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group email">
                    <label className="no-text" htmlFor="newEmail">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="newEmail"
                      id="newEmail"
                      value={inputs.newEmail}
                      className="form-control form-control-outline input-full thick form-success-clean"
                      onChange={handleInputChange}
                      autoComplete="new-password"
                      required
                    />
                  </div>
                  <div className="form-group no-border">
                    <label className="no-text" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      placeholder="Message ..."
                      name="message"
                      id="message"
                      value={inputs.message}
                      className="form-control form-control-outline thick form-success-clean"
                      onChange={handleInputChange}
                      autoComplete="new-password"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <button
                    className="btn btn-outline btn-primary email_b"
                    name="submit_message"
                    disabled={disabled}
                    type="submit"
                  >
                    {disabled ? (
                      <>
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="sr-only">Sending...</span>
                        </div>
                        <span className="txt ml-1">Sending...</span>
                      </>
                    ) : (
                      <span className="txt">Send Now</span>
                    )}
                    <span className="arrow-icon"></span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
});
